const URL_HOME = '/';
const URL_LOGIN = '/login';
const URL_REGISTER = '/signup';
const URL_VERIFY_ACCOUNT = '/verify_account';
const URL_VERIFY_EMAIL = '/verify_mail';
const URL_ASSETS = '/assets';
const URL_PIPELINES = '/pipelines';
const URL_WORKFLOWS = '/workflows';
const URL_SETTINGS = '/settings';
const URL_EMBEDED = '/embeded';
const URL_PUBLISHED = '/published';
const URL_CLI = '/cli';
const URL_WEBHOOKS = '/webhooks';
const URL_USAGE_STATISTICS = '/usageStatistics';
const URL_FORGOT_PASSWORD = '/forgotPassword';
const URL_API_TOKENS = '/api_tokens';
const URL_CHOOSE_PLAN = '/choose_plan';
const URL_PAYMENT_INFO = '/choose_plan/payment_info';
const URL_SUMMARY = '/choose_plan/payment_info/summary';
const URL_SUCCESS = '/choose_plan/success';
const URL_ADMIN = '/admin';
const URL_COMPLETE_REGISTRATION = '/complete_registration';
const URL_SCALE_WIDGET = '/scale_widget';
const URL_DESTROY_SESSION = '/destroy_session';
const URL_PLUGINS = '/plugins';
const urlHelper = {
  home: () => URL_HOME,
  login: () => URL_LOGIN,
  register: () => URL_REGISTER,
  verifyAccount: () => URL_VERIFY_ACCOUNT,
  verifyEmail: () => URL_VERIFY_EMAIL,
  assets: () => URL_ASSETS,
  pipelines: () => URL_PIPELINES,
  workflows: () => URL_WORKFLOWS,
  settings: () => URL_SETTINGS,
  embeded: (rapidId?: number) => {
    if (rapidId === undefined) {
      return URL_EMBEDED;
    }
    return `${URL_EMBEDED}/rapid/${rapidId}`;
  },
  published: () => URL_PUBLISHED,
  cli: () => URL_CLI,
  plugins: () => URL_PLUGINS,
  webhooks: () => URL_WEBHOOKS,
  usageStatistics: () => URL_USAGE_STATISTICS,
  forgotPassword: () => URL_FORGOT_PASSWORD,
  apiTokens: () => URL_API_TOKENS,
  plan: {
    choosePlan: () => URL_CHOOSE_PLAN,
    paymentInfo: () => URL_PAYMENT_INFO,
    summary: () => URL_SUMMARY,
    success: () => URL_SUCCESS,
  },
  admin: () => URL_ADMIN,
  completeRegistration: () => URL_COMPLETE_REGISTRATION,
  scaleWidget: () => URL_SCALE_WIDGET,
  destroySession: () => URL_DESTROY_SESSION,
};

export default urlHelper;
